.ServiceCard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 3em 2em;
  text-align: center;
  background-color: #fefefe;
  box-shadow: 0px 0px 20.1px rgba(0, 0, 0, 0.02),
    0px 0px 35.2px rgba(0, 0, 0, 0.028), 0px 0px 45.1px rgba(0, 0, 0, 0.035),
    0px 0px 50.1px rgba(0, 0, 0, 0.042), 0px 0px 53px rgba(0, 0, 0, 0.05),
    0px 0px 80px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  border: 1px solid #fdfdfd;
  outline: 1px solid #fdfdfd;
  flex: 0 0 100%;
  min-width: 250px;
  transition: all 0.1s ease-out;
}

.ServiceCard:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.serviceName {
  font-size: clamp(1.5em, 3vw, 2em);
  font-weight: 600;
}

.serviceTime {
  font-size: 1em;
  color: #605959;
  margin-top: 1em;
}

.servicePrice {
  font-size: 1.2em;
  margin-top: 1em;
}

.serviceIcon > img {
  margin-bottom: 1em;
  width: clamp(7em, 5vw, 10em);
}
