.storiaWrapper {
  margin-top: 7em;
}

.storiaWrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2em;
}

.light {
  color: #272727;
  background-color: #fff;
}

.dark {
  color: #272727;
  background-color: #f7f7f7;
}

.storiaDescription {
  padding: 0 1em;
  flex: 1 1 40%;
}

.storiaImage {
  flex: 1 1 60%;
}

.storiaDescription,
.storiaImage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  font-size: clamp(0.5em, 2vw, 1.3em);
  font-weight: 500;
}

.storiaImage img {
  width: 55%;
  object-fit: contain;
  border-radius: 3px;
}

@media screen and (max-width: 650px) {
  .storiaWrapper > div {
    display: flex;
    flex-direction: column;
  }
  .storiaDescription,
  .storiaImage {
    font-size: 0.7em;
    text-align: center;
    padding: 2em 0;
  }
  .dark {
    flex-direction: column-reverse !important;
  }
  .storiaImage img {
    width: 60%;
  }
}
