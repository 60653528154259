.prenotazioniWrapper {
  display: flex;
  justify-content: center;
  background-color: #fdfdfd;
  margin-top: 7em;
  height: 100%;
}

.prenotazioniUpper {
  flex: 1 1 100%;
}

.prenotazioniUpper > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  padding: 1.5em;
}

.prenotazioniUpper {
  display: flex;
  justify-content: center;
}

.timeTableWrapper > h1 {
  text-align: center;
  margin-bottom: 1em;
  font-size: clamp(1.2em, 5vw, 2.6em);
}

.timeTableList {
  width: 100%;
  font-size: clamp(0.5em, 3vw, 1.2em);
}

.timeTableList > ul {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
  border-bottom: 1px solid #272727;
}

.reserve {
  text-align: center;
}

.reserve > h1 {
  margin-bottom: 1.5em;
  font-size: clamp(1.2em, 5vw, 2.6em);
}

.additionalMargin {
  margin-top: 1.5em;
}

.ctaLink {
  font-size: clamp(0.9em, 3vw, 1.5em);
  padding: 0.5em 2em;
  border: 1px solid #272727;
  border-radius: 10px;
  transition: all 0.1s ease-out;
}

.ctaLink:hover {
  color: #fdfdfd;
  background-color: #272727;
}

.storeLink > img {
  width: 17em;
}

.storeLink:first-of-type {
  margin-right: 1em;
  font-size: clamp(0.6em, 1vw, 0.8em);
}

.storeLink:last-of-type {
  margin-left: 1em;
  font-size: clamp(0.6em, 1vw, 0.8em);
}

.appLinks > div {
  display: inline-block;
}

@media screen and (max-width: 875px) {
  .prenotazioniWrapper {
    flex-direction: column;
    justify-content: flex-start;
    height: max-content;
  }

  .prenotazioniUpper {
    flex-direction: column;
  }

  .timeTableWrapper > h1,
  .reserve > h1 {
    font-size: clamp (0.2em, 1vw, 1.2em) !important;
  }

  .appLinks > div {
    display: block;
  }
}
