header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 2em 1.5em;
  z-index: 1000;
}

.primaryNavigation {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(1.2em , 2.5vw, 1.75em);
  font-weight: 400;
  letter-spacing: -2px;
  gap: 4.5em;
}

.tabLink {
  color: #fafafa;
  padding: .2em 1em;
  border-radius: 5px;
  transition: all .1s ease-in;
}

.tabLink:hover {
  color: #000;
  background-color: #fff;
  opacity: .5;
}
