.Home {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url(../../assets/images/hero_1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  color: #212427;
  font-size: clamp(1.3em, 2.5vw, 1.75em);
  padding: 2em 3em;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 7px 7px 30px -5px #dfdfdf;
  transition: all 0.1s ease-out;
}

.description:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.description blockquote > span {
  display: block;
  text-align: center;
  margin: 1em;
}

.overlay {
  position: absolute;
  inset: 0;
  background-color: #000;
  opacity: 0.3;
}

@media screen and (max-width: 650px) {
  .description {
    font-size: 1.2em;
    padding: 2em 1em;
  }
}
