@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,regular,500,600,700,800,300italic,italic,500italic,600italic,700italic,800italic);

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

html {
  height: 100%;
}

body {
  height: 100%;
  overflow-x: hidden;
  background: #3c3939;
}

ul,
ol {
  list-style: none;
}

blockquote {
  position: relative;
  letter-spacing: -2px;
}

blockquote::before {
  content: '"';
  font-size: 2em;
  position: absolute;
  top: -40%;
  left: 0;
  color: #212427;
}

blockquote::after {
  content: '"';
  font-size: 2em;
  position: absolute;
  bottom: -50%;
  right: 0;
  color: #212427;
}

time {
  color: #605959;
}

.link {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  blockquote::after,
  blockquote::before {
    font-size: 1.5em;
  }
}

#root {
  height: 100%;
}