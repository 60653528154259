.menuIcon {
  background: none;
  border: none;
  outline: none;
  appearance: none;
  position: absolute;
  top: 2em;
  right: 2em;
}

.menuIcon > img {
  width: 2em;
}
